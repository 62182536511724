<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Bekleyen Talepler</b-card-title>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(title)="data">
        {{ data.item.title }}
      </template>
      <template #cell(status)="data">
        <FeatherIcon
          :class="data.item.status === '1'? 'text-success' : 'text-warning'"
          :icon="data.item.status === '1'? 'CheckIcon' : 'ClockIcon'"
        />
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          size="sm"
          :to="'/admin/supports/view/' + data.item.id"
        >
          Görüntüle
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BTable, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BButton,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'subject',
          label: 'KONU',
        },
        {
          key: 'project',
          label: 'PROJE',
        },
        {
          key: 'support_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
}
</script>
