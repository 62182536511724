<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Onay Bekleyen Talepler</b-card-title>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(subject)="data">
        <div :class="data.item.id_priorities === '1'? 'text-danger' : ''">
          {{ data.item.subject }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.customer_user }}
        </div>
        <div class="text-info font-small-2">
          {{ data.item.project }}
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          :to="'/client/supports/view/' + data.item.id"
          size="sm"
        >
          Görüntüle
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BCardHeader, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'Pending',
  components: {
    BCard,
    BTable,
    BButton,
    BCardHeader,
    BCardTitle,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'subject',
          label: 'KONU',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
}
</script>
