<template>
  <div>
    <support-statistics :statistic-data="counters" />
    <pending-list :data-list="pendingList" />
  </div>
</template>

<script>
import SupportStatistics from '@/views/Admin/Dashboard/SupportStatistics.vue'
import PendingList from '@/views/Admin/Dashboard/PendingList.vue'

export default {
  name: 'Index',
  components: {
    SupportStatistics,
    PendingList,
  },
  computed: {
    counters() {
      return this.$store.getters['dashboard/counters']
    },
    pendingList() {
      return this.$store.getters['dashboard/pendingList']
    },
  },
  created() {
    this.getCounters()
    this.getPendingList()
  },
  methods: {
    getCounters() {
      this.$store.dispatch('dashboard/getCounters')
    },
    getPendingList() {
      this.$store.dispatch('dashboard/getPendingList')
    },
  },
}
</script>
