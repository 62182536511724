<template>
  <div>
    <b-card :title="cardTitle">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <project />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <priorities />
        </b-col>
        <b-col
          cols="12"
        >
          <subject />
        </b-col>
        <b-col
          cols="12"
        >
          <notes />
        </b-col>
        <b-col cols="12">
          <upload-file />
        </b-col>
      </b-row>
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Project from '@/views/Client/Supports/elements/Project.vue'
import Subject from '@/views/Client/Supports/elements/Subject.vue'
import Priorities from '@/views/Client/Supports/elements/Priorities.vue'
import Notes from '@/views/Client/Supports/elements/Notes.vue'
import UploadFile from '@/views/Client/Supports/elements/UploadFile.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'SupportForm',
  components: {
    BCard,
    BRow,
    BCol,
    Project,
    Subject,
    Priorities,
    Notes,
    UploadFile,
    SaveButton,
  },
  props: {
    cardTitle: {
      type: String,
      default: null,
    },
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['clientSupports/dataItem']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
