<template>
  <component :is="dashboardType" />
</template>

<script>
import ClientDashboard from '@/views/Client/Dashboard/Index.vue'
import AdminDashboard from '@/views/Admin/Dashboard/Index.vue'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    ClientDashboard,
    AdminDashboard,
  },
  data() {
    return {
      userData: null,
      dashboardType: null,
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    getUserData() {
      this.userData = JSON.parse(localStorage.getItem('userData'))
      if (this.userData.type === 'admin') {
        this.dashboardType = 'AdminDashboard'
      } else {
        this.dashboardType = 'ClientDashboard'
      }
    },
  },
}
</script>
