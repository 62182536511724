<template>
  <b-form-group
    label="Öncelik Durumu"
    label-for="status"
  >
    <validation-provider
      #default="{ errors }"
      name="Öncelik Durumu"
      rules="required"
    >
      <v-select
        id="status"
        v-model="dataItem.id_priorities"
        :options="priorities"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Priorities',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['clientSupports/dataItem']
    },
    priorities() {
      return this.$store.getters['clientPriorities/dataList']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('clientPriorities/getDataList', {
        select: [
          'priorities.id AS id',
          'priorities.title AS title',
        ],
      })
    },
  },
}
</script>
