<template>
  <b-row>
    <b-col
      cols="12"
      :md="pendingList.length > 0? 6 : 12"
    >
      <support />
    </b-col>
    <b-col
      v-if="pendingList.length > 0"
      cols="12"
      md="6"
    >
      <pending :data-list="pendingList" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Support from '@/views/Client/Dashboard/Support.vue'
import Pending from '@/views/Client/Dashboard/Pending.vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    Pending,
    Support,
  },
  computed: {
    pendingList() {
      return this.$store.getters['clientDashboard/pendingList']
    },
  },
  created() {
    this.getPendingApproval()
  },
  methods: {
    getPendingApproval() {
      this.$store.dispatch('clientDashboard/getPendingApproval')
    },
  },
}
</script>
